import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

document.addEventListener('DOMContentLoaded', () => {
  //alert("iam typeform.js");

  const formButton = document.querySelector('#open-typeform');
  if (formButton) {
    formButton.addEventListener('click', () => {
        alert("working 1"),
        createPopup('01JD87TFD7XXP20HE88MZQN488', { // Bu id typeform hesabından geliyor.
        onSubmit: () => console.log('Form submitted!'),
      }).open();
    });
  }
});
